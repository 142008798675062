
<script>
import Avatar from '../Common/Avatar.vue';
import Icon from '../Common/Icon.vue';

export default {
  components: { Avatar, Icon },
  name: 'UsersTaskManagement',

  data() { return {
    waitRepsonse: false,
    userList: [],
    selectedUser: null,
  }},

  methods: {
    SelectUser(index)
    {
      if (this.selectedUser) {
        this.selectedUser = null;
        return;
      }

      this.selectedUser = this.userList[index];
    },

    OnSearchResult({users})
    {
      this.userList = users;
      this.selectedUser = null;
      this.waitRepsonse = false;
    },

    OnSearch()
    {
      this.waitRepsonse = true;
    }
  },
}
</script>

<template>
  <div class="body">

    <div class="bordered-box filter-box">
      <user-search-input :search-on-mount="true" @result="OnSearchResult" @search="OnSearch"></user-search-input>
    </div>

    <div class="center-box" v-if="waitRepsonse">
      <loading></loading>
    </div>

    <div v-else>
      <h3 class="center-box" v-if="!userList.length">
        {{$lan('no_results')}}
      </h3>

      <div v-if="selectedUser">
        <div @click="SelectUser(i)" class="user-row">
          <div class="task-profile">
            <avatar :active="selectedUser.is_active" :url="selectedUser.picture" :f-name="selectedUser.f_name" :s-name="selectedUser.s_name"></avatar>
            <span class="name">{{selectedUser.f_name}} {{selectedUser.s_name}}</span>
          </div>

          <div class="right-side">
            <user-actions :user-data="selectedUser" v-if="!$utils.isMobile()"></user-actions>
            <icon class="red">close</icon>
          </div>
        </div>

        <div class="user-actions" v-if="$utils.isMobile()">
          <user-actions :user-data="selectedUser"></user-actions>
        </div>

        <tasks-view :userID="selectedUser.id" />
      </div> 
      
      <div v-else class="list-box">
        <div v-for="(user, i) in userList" :key="i">
          <div @click="SelectUser(i)" class="user-row">
            <div class="task-profile">
              <avatar :active="user.is_active" :url="user.picture" :f-name="user.f_name" :s-name="user.s_name" :platform="user.platform"></avatar>
              <span class="name">{{user.f_name}} {{user.s_name}}</span>
            </div>

            <div class="right-side">
              <icon>
                unfold_more
              </icon>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>


<style scoped>
.body {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  padding: 2px;
  gap: 10px;
}

.user-row {
  padding: 10px;
  position: relative;
  border-radius: 20px;
  background: var(--box-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
}

.user-row:hover {
  outline: solid 1px rgb(0, 119, 255);
}

.task-profile {
  display: flex;
  align-items: center;
}

.avatar-container {
  position: relative;
  float: left;
  margin-left: 15px;
  height: 55px;
  width: 55px;
  font-size: 10px;
}

.name {
  font-weight: bold;
  margin-left: 10px;
  font-size: 20px;
  color: var(--text-color);
}

.right-side {
  display: flex;
  gap: 10px;
  font-size: 20px;
  align-items: center;
  padding-right: 20px;
}

.filter-box {
  width: 100%;
  overflow: hidden;
}

.user-actions {
  padding: 10px;
  width: fit-content;
  display: block;
  margin: auto;
  background-color: var(--box-color2);
  border-radius: var(--border-radius);
  margin-bottom: 10px;
}
</style>

