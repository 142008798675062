<script>
import UsersTaskManagement from '../../components/Management/UsersTaskManagement.vue'


export default {
  name: 'AdminPage',
  components: {UsersTaskManagement},

}
</script>


<template>
  <div class="admin-page">
    <UsersTaskManagement />
  </div>
</template>


<style scoped>
.admin-page {
  flex-direction: column;
  gap: 10px;
}
</style>

